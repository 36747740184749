/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TemplateReportResponse
 */
export interface TemplateReportResponse {
    /**
     * 
     * @type {number}
     * @memberof TemplateReportResponse
     */
    readonly id: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateReportResponse
     */
    nModelsMin: number;
    /**
     * 
     * @type {number}
     * @memberof TemplateReportResponse
     */
    nModelsMax: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateReportResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateReportResponse
     */
    description: string;
}

/**
 * Check if a given object implements the TemplateReportResponse interface.
 */
export function instanceOfTemplateReportResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "nModelsMin" in value;
    isInstance = isInstance && "nModelsMax" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function TemplateReportResponseFromJSON(json: any): TemplateReportResponse {
    return TemplateReportResponseFromJSONTyped(json, false);
}

export function TemplateReportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TemplateReportResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'nModelsMin': json['n_models_min'],
        'nModelsMax': json['n_models_max'],
        'name': json['name'],
        'description': json['description'],
    };
}

export function TemplateReportResponseToJSON(value?: TemplateReportResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'n_models_min': value.nModelsMin,
        'n_models_max': value.nModelsMax,
        'name': value.name,
        'description': value.description,
    };
}

