/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetPasswordSendMail
 */
export interface ResetPasswordSendMail {
    /**
     * 
     * @type {string}
     * @memberof ResetPasswordSendMail
     */
    email: string;
}

/**
 * Check if a given object implements the ResetPasswordSendMail interface.
 */
export function instanceOfResetPasswordSendMail(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "email" in value;

    return isInstance;
}

export function ResetPasswordSendMailFromJSON(json: any): ResetPasswordSendMail {
    return ResetPasswordSendMailFromJSONTyped(json, false);
}

export function ResetPasswordSendMailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResetPasswordSendMail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function ResetPasswordSendMailToJSON(value?: ResetPasswordSendMail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
    };
}

