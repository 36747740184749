/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PatchedProjectUpdateRequest,
  Project,
  ProjectCreateRequest,
  ProjectCreateResponse,
  ProjectInfoResponse,
  ProjectListResponse,
  ProjectUpdateRequest,
} from '../models';
import {
    PatchedProjectUpdateRequestFromJSON,
    PatchedProjectUpdateRequestToJSON,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectCreateRequestFromJSON,
    ProjectCreateRequestToJSON,
    ProjectCreateResponseFromJSON,
    ProjectCreateResponseToJSON,
    ProjectInfoResponseFromJSON,
    ProjectInfoResponseToJSON,
    ProjectListResponseFromJSON,
    ProjectListResponseToJSON,
    ProjectUpdateRequestFromJSON,
    ProjectUpdateRequestToJSON,
} from '../models';

export interface ApiGrexmanagerProjectCreateRequest {
    projectCreateRequest: ProjectCreateRequest;
}

export interface ApiGrexmanagerProjectDestroyRequest {
    id: number;
}

export interface ApiGrexmanagerProjectDuplicateRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerProjectListRequest {
    all?: boolean;
}

export interface ApiGrexmanagerProjectPartialUpdateRequest {
    id: number;
    patchedProjectUpdateRequest?: PatchedProjectUpdateRequest;
}

export interface ApiGrexmanagerProjectRetrieveRequest {
    id: number;
}

export interface ApiGrexmanagerProjectUpdateRequest {
    id: number;
    projectUpdateRequest: ProjectUpdateRequest;
}

/**
 * 
 */
export class ProjectApi extends runtime.BaseAPI {

    /**
     * Endpoint for creating a project
     */
    async apiGrexmanagerProjectCreateRaw(requestParameters: ApiGrexmanagerProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectCreateResponse>> {
        if (requestParameters.projectCreateRequest === null || requestParameters.projectCreateRequest === undefined) {
            throw new runtime.RequiredError('projectCreateRequest','Required parameter requestParameters.projectCreateRequest was null or undefined when calling apiGrexmanagerProjectCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectCreateRequestToJSON(requestParameters.projectCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCreateResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for creating a project
     */
    async apiGrexmanagerProjectCreate(requestParameters: ApiGrexmanagerProjectCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectCreateResponse> {
        const response = await this.apiGrexmanagerProjectCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting a project
     */
    async apiGrexmanagerProjectDestroyRaw(requestParameters: ApiGrexmanagerProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerProjectDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Endpoint for deleting a project
     */
    async apiGrexmanagerProjectDestroy(requestParameters: ApiGrexmanagerProjectDestroyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.apiGrexmanagerProjectDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * Endpoint for duplicating a project
     */
    async apiGrexmanagerProjectDuplicateRetrieveRaw(requestParameters: ApiGrexmanagerProjectDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerProjectDuplicateRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/{id}/duplicate/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for duplicating a project
     */
    async apiGrexmanagerProjectDuplicateRetrieve(requestParameters: ApiGrexmanagerProjectDuplicateRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.apiGrexmanagerProjectDuplicateRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving a list of projects the GrexUser has access to
     */
    async apiGrexmanagerProjectListRaw(requestParameters: ApiGrexmanagerProjectListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ProjectListResponse>>> {
        const queryParameters: any = {};

        if (requestParameters.all !== undefined) {
            queryParameters['all'] = requestParameters.all;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectListResponseFromJSON));
    }

    /**
     * Endpoint for receiving a list of projects the GrexUser has access to
     */
    async apiGrexmanagerProjectList(requestParameters: ApiGrexmanagerProjectListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ProjectListResponse>> {
        const response = await this.apiGrexmanagerProjectListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating project information
     */
    async apiGrexmanagerProjectPartialUpdateRaw(requestParameters: ApiGrexmanagerProjectPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerProjectPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedProjectUpdateRequestToJSON(requestParameters.patchedProjectUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating project information
     */
    async apiGrexmanagerProjectPartialUpdate(requestParameters: ApiGrexmanagerProjectPartialUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.apiGrexmanagerProjectPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for receiving more detailed information about a project if you have access to the project
     */
    async apiGrexmanagerProjectRetrieveRaw(requestParameters: ApiGrexmanagerProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ProjectInfoResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerProjectRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectInfoResponseFromJSON(jsonValue));
    }

    /**
     * Endpoint for receiving more detailed information about a project if you have access to the project
     */
    async apiGrexmanagerProjectRetrieve(requestParameters: ApiGrexmanagerProjectRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ProjectInfoResponse> {
        const response = await this.apiGrexmanagerProjectRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for updating project information
     */
    async apiGrexmanagerProjectUpdateRaw(requestParameters: ApiGrexmanagerProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerProjectUpdate.');
        }

        if (requestParameters.projectUpdateRequest === null || requestParameters.projectUpdateRequest === undefined) {
            throw new runtime.RequiredError('projectUpdateRequest','Required parameter requestParameters.projectUpdateRequest was null or undefined when calling apiGrexmanagerProjectUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/project/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectUpdateRequestToJSON(requestParameters.projectUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Endpoint for updating project information
     */
    async apiGrexmanagerProjectUpdate(requestParameters: ApiGrexmanagerProjectUpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Project> {
        const response = await this.apiGrexmanagerProjectUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
