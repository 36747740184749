/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionModelMoveRequest
 */
export interface VersionModelMoveRequest {
    /**
     * 
     * @type {number}
     * @memberof VersionModelMoveRequest
     */
    toProject: number;
}

/**
 * Check if a given object implements the VersionModelMoveRequest interface.
 */
export function instanceOfVersionModelMoveRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "toProject" in value;

    return isInstance;
}

export function VersionModelMoveRequestFromJSON(json: any): VersionModelMoveRequest {
    return VersionModelMoveRequestFromJSONTyped(json, false);
}

export function VersionModelMoveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelMoveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toProject': json['to_project'],
    };
}

export function VersionModelMoveRequestToJSON(value?: VersionModelMoveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to_project': value.toProject,
    };
}

