/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersionModelCreateResponse
 */
export interface VersionModelCreateResponse {
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateResponse
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateResponse
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateResponse
     */
    phase?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof VersionModelCreateResponse
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateResponse
     */
    author: number;
    /**
     * 
     * @type {number}
     * @memberof VersionModelCreateResponse
     */
    project: number;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateResponse
     */
    viewerUri: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateResponse
     */
    outlineUri: string;
    /**
     * 
     * @type {string}
     * @memberof VersionModelCreateResponse
     */
    editorUri: string;
}

/**
 * Check if a given object implements the VersionModelCreateResponse interface.
 */
export function instanceOfVersionModelCreateResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "author" in value;
    isInstance = isInstance && "project" in value;
    isInstance = isInstance && "viewerUri" in value;
    isInstance = isInstance && "outlineUri" in value;
    isInstance = isInstance && "editorUri" in value;

    return isInstance;
}

export function VersionModelCreateResponseFromJSON(json: any): VersionModelCreateResponse {
    return VersionModelCreateResponseFromJSONTyped(json, false);
}

export function VersionModelCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersionModelCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'name': json['name'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'author': json['author'],
        'project': json['project'],
        'viewerUri': json['viewer_uri'],
        'outlineUri': json['outline_uri'],
        'editorUri': json['editor_uri'],
    };
}

export function VersionModelCreateResponseToJSON(value?: VersionModelCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'name': value.name,
        'phase': value.phase,
        'author': value.author,
        'project': value.project,
        'viewer_uri': value.viewerUri,
        'outline_uri': value.outlineUri,
        'editor_uri': value.editorUri,
    };
}

