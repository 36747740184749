/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * * `Bearer` - Bearer
 * * `MAC` - MAC
 * @export
 */
export const TokenTypeEnum = {
    Bearer: 'Bearer',
    Mac: 'MAC'
} as const;
export type TokenTypeEnum = typeof TokenTypeEnum[keyof typeof TokenTypeEnum];


export function TokenTypeEnumFromJSON(json: any): TokenTypeEnum {
    return TokenTypeEnumFromJSONTyped(json, false);
}

export function TokenTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenTypeEnum {
    return json as TokenTypeEnum;
}

export function TokenTypeEnumToJSON(value?: TokenTypeEnum | null): any {
    return value as any;
}

