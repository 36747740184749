/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    access: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    mfa: boolean;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    user: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginResponse
     */
    message: string;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "access" in value;
    isInstance = isInstance && "mfa" in value;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'access': json['access'],
        'mfa': json['mfa'],
        'user': json['user'],
        'message': json['message'],
    };
}

export function LoginResponseToJSON(value?: LoginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access': value.access,
        'mfa': value.mfa,
        'user': value.user,
        'message': value.message,
    };
}

