/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LinkBI
 */
export interface LinkBI {
    /**
     * 
     * @type {number}
     * @memberof LinkBI
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    uuid: string;
    /**
     * 
     * @type {Date}
     * @memberof LinkBI
     */
    readonly timestampCreated: Date;
    /**
     * 
     * @type {Date}
     * @memberof LinkBI
     */
    timestampLastAccessed?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    versionApi: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    password: string;
    /**
     * 
     * @type {Date}
     * @memberof LinkBI
     */
    expirationPassword: Date;
    /**
     * 
     * @type {string}
     * @memberof LinkBI
     */
    ipaddress: string;
}

/**
 * Check if a given object implements the LinkBI interface.
 */
export function instanceOfLinkBI(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "uuid" in value;
    isInstance = isInstance && "timestampCreated" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "versionApi" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "password" in value;
    isInstance = isInstance && "expirationPassword" in value;
    isInstance = isInstance && "ipaddress" in value;

    return isInstance;
}

export function LinkBIFromJSON(json: any): LinkBI {
    return LinkBIFromJSONTyped(json, false);
}

export function LinkBIFromJSONTyped(json: any, ignoreDiscriminator: boolean): LinkBI {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'uuid': json['uuid'],
        'timestampCreated': (new Date(json['timestamp_created'])),
        'timestampLastAccessed': !exists(json, 'timestamp_last_accessed') ? undefined : (json['timestamp_last_accessed'] === null ? null : new Date(json['timestamp_last_accessed'])),
        'url': json['url'],
        'versionApi': json['version_api'],
        'description': json['description'],
        'password': json['password'],
        'expirationPassword': (new Date(json['expiration_password'])),
        'ipaddress': json['ipaddress'],
    };
}

export function LinkBIToJSON(value?: LinkBI | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uuid': value.uuid,
        'timestamp_last_accessed': value.timestampLastAccessed === undefined ? undefined : (value.timestampLastAccessed === null ? null : value.timestampLastAccessed.toISOString()),
        'url': value.url,
        'version_api': value.versionApi,
        'description': value.description,
        'password': value.password,
        'expiration_password': (value.expirationPassword.toISOString()),
        'ipaddress': value.ipaddress,
    };
}

