import { RoleEnum } from "@shared/client/lib";
import GrexManagerLogo from "@shared/components/GrexManagerLogo";
import React from "react";
import {
  FiArchive,
  FiBarChart,
  FiBarChart2,
  FiBook,
  FiFolder,
  FiLink,
  FiLogOut,
  FiMapPin,
  FiSettings,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { AppStateHandler } from "../AppStateHandler";
import { AppStateType, ScreenState } from "../Types";
import "./MenuBar.css";

interface MenuBarProps {
  state: AppStateType;
  stateHandler: AppStateHandler;
  updateStateCallback: (newState: AppStateType) => void;
}

export default class MenuBar extends React.Component<MenuBarProps> {
  openSubmenu: React.MouseEventHandler<HTMLDivElement> = (event) => {
    document
      .getElementById("MenuBar-SubMenuBar")!
      .classList.add("MenuBar-SubMenuBarVisible");
  };

  closeSubmenu: React.MouseEventHandler<HTMLDivElement> = (event) => {
    document
      .getElementById("MenuBar-SubMenuBar")!
      .classList.remove("MenuBar-SubMenuBarVisible");
  };

  render() {
    return (
      <div id="MenuBar-Holder" onMouseLeave={this.closeSubmenu}>
        <div id="MenuBar-Main">
          <GrexManagerLogo
            onClick={() => {
              this.props.stateHandler.changeScreen(
                ScreenState.Projects,
                this.props.updateStateCallback
              );
            }}
          />

          <div id="MenuBar-MenuScrollHolder">
            <div className="MenuBar-MenuFlexHolder">
              <div>
                <div
                  className={
                    this.props.state.screenState === ScreenState.Projects
                      ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                      : "MenuBar-NavItem MenuBar-NavItemMargin"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.Projects,
                      this.props.updateStateCallback
                    );
                  }}
                  onMouseEnter={this.closeSubmenu}
                >
                  <div className="MenuBar-NavIcon">
                    <FiFolder />
                  </div>
                  <div className="MenuBar-NavText">Projecten</div>
                </div>

                <div
                  className={
                    this.props.state.screenState === ScreenState.Reports
                      ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                      : "MenuBar-NavItem MenuBar-NavItemMargin"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.Reports,
                      this.props.updateStateCallback
                    );
                  }}
                  onMouseEnter={this.closeSubmenu}
                >
                  <div className="MenuBar-NavIcon">
                    <FiBarChart2 />
                  </div>
                  <div className="MenuBar-NavText">Rapportages</div>
                </div>

                <div
                  className={
                    this.props.state.screenState === ScreenState.Documentation
                      ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                      : "MenuBar-NavItem MenuBar-NavItemMargin"
                  }
                  onClick={(_) => {
                    this.props.stateHandler.changeScreen(
                      ScreenState.Documentation,
                      this.props.updateStateCallback
                    );
                  }}
                  onMouseEnter={this.closeSubmenu}
                >
                  <div className="MenuBar-NavIcon">
                    <FiBook />
                  </div>
                  <div className="MenuBar-NavText">Documentatie</div>
                </div>

                {(() => {
                  if (this.props.state.currentUser.userRole === RoleEnum.Adm) {
                    return (
                      <>
                        <div
                          className={
                            this.props.state.screenState ===
                              ScreenState.Configuration_Subregions ||
                            this.props.state.screenState ===
                              ScreenState.Configuration_PhaseDefinitions ||
                            this.props.state.screenState ===
                              ScreenState.Configuration_FileCategories ||
                            this.props.state.screenState ===
                              ScreenState.Configuration_TypeProject ||
                            this.props.state.screenState ===
                              ScreenState.MapViewer_Client
                              ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                              : "MenuBar-NavItem MenuBar-NavItemMargin"
                          }
                          onMouseEnter={this.openSubmenu}
                        >
                          <div className="MenuBar-NavIcon">
                            <FiSettings />
                          </div>
                          <div className="MenuBar-NavText">Configuratie</div>
                        </div>

                        <div
                          className={
                            this.props.state.screenState ===
                            ScreenState.UserList
                              ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                              : "MenuBar-NavItem MenuBar-NavItemMargin"
                          }
                          onClick={(_) => {
                            this.props.stateHandler.changeScreen(
                              ScreenState.UserList,
                              this.props.updateStateCallback
                            );
                          }}
                          onMouseEnter={this.closeSubmenu}
                        >
                          <div className="MenuBar-NavIcon">
                            <FiUsers />
                          </div>
                          <div className="MenuBar-NavText">Gebruikers</div>
                        </div>

                        <div
                          className={
                            this.props.state.screenState === ScreenState.Logs
                              ? "MenuBar-NavItem MenuBar-NavItemSelected"
                              : "MenuBar-NavItem"
                          }
                          onClick={(_) => {
                            this.props.stateHandler.changeScreen(
                              ScreenState.Logs,
                              this.props.updateStateCallback
                            );
                          }}
                          onMouseEnter={this.closeSubmenu}
                        >
                          <div className="MenuBar-NavIcon">
                            <FiArchive />
                          </div>
                          <div className="MenuBar-NavText">Logs</div>
                        </div>
                      </>
                    );
                  }
                })()}
              </div>
            </div>
          </div>

          <div id="MenuBar-UserItemFlexHolder">
            <div id="MenuBar-UserItemHolder">
              <div
                className={"MenuBar-NavItem"}
                onClick={(_) => {
                  this.props.stateHandler.logout(
                    this.props.updateStateCallback
                  );
                }}
                onMouseEnter={this.closeSubmenu}
              >
                <div className="MenuBar-NavIcon">
                  <FiLogOut />
                </div>
                <div className="MenuBar-NavText">Uitloggen</div>
              </div>

              <div
                className={
                  this.props.state.screenState === ScreenState.User
                    ? "MenuBar-NavItem MenuBar-NavItemSelected"
                    : "MenuBar-NavItem"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.User,
                    this.props.updateStateCallback
                  );
                }}
                onMouseEnter={this.closeSubmenu}
              >
                <div className="MenuBar-NavIcon">
                  <FiUser />
                </div>
                <div className="MenuBar-NavText">Gebruiker</div>
              </div>
            </div>
          </div>
        </div>

        <div id="MenuBar-SubMenuBar">
          <div className="MenuBar-MenuFlexHolder">
            <div>
              <div
                className={
                  this.props.state.screenState === ScreenState.MapViewer_Client
                    ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                    : "MenuBar-NavItem MenuBar-NavItemMargin"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.MapViewer_Client,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiMapPin />
                </div>
                <div className="MenuBar-NavText">Klantgebied</div>
              </div>

              <div
                className={
                  this.props.state.screenState ===
                  ScreenState.Configuration_Subregions
                    ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                    : "MenuBar-NavItem MenuBar-NavItemMargin"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.Configuration_Subregions,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiMapPin />
                </div>
                <div className="MenuBar-NavText">Deelgebieden</div>
              </div>

              <div
                className={
                  this.props.state.screenState ===
                  ScreenState.Configuration_PhaseDefinitions
                    ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                    : "MenuBar-NavItem MenuBar-NavItemMargin"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.Configuration_PhaseDefinitions,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiBarChart />
                </div>
                <div className="MenuBar-NavText">Fasedefinities</div>
              </div>

              <div
                className={
                  this.props.state.screenState ===
                  ScreenState.Configuration_FileCategories
                    ? "MenuBar-NavItem MenuBar-NavItemMargin MenuBar-NavItemSelected"
                    : "MenuBar-NavItem MenuBar-NavItemMargin"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.Configuration_FileCategories,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiLink />
                </div>
                <div className="MenuBar-NavText">Bijlagecategorieën</div>
              </div>

              <div
                className={
                  this.props.state.screenState ===
                  ScreenState.Configuration_TypeProject
                    ? "MenuBar-NavItem MenuBar-NavItemSelected"
                    : "MenuBar-NavItem"
                }
                onClick={(_) => {
                  this.props.stateHandler.changeScreen(
                    ScreenState.Configuration_TypeProject,
                    this.props.updateStateCallback
                  );
                }}
              >
                <div className="MenuBar-NavIcon">
                  <FiFolder />
                </div>
                <div className="MenuBar-NavText">Projecttypen</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
