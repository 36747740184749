/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelInfoMutationResponse } from './ModelInfoMutationResponse';
import {
    ModelInfoMutationResponseFromJSON,
    ModelInfoMutationResponseFromJSONTyped,
    ModelInfoMutationResponseToJSON,
} from './ModelInfoMutationResponse';
import type { ProjectRoleEnum } from './ProjectRoleEnum';
import {
    ProjectRoleEnumFromJSON,
    ProjectRoleEnumFromJSONTyped,
    ProjectRoleEnumToJSON,
} from './ProjectRoleEnum';

/**
 * 
 * @export
 * @interface ModelInfoResponse
 */
export interface ModelInfoResponse {
    /**
     * 
     * @type {number}
     * @memberof ModelInfoResponse
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoResponse
     */
    description: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ModelInfoResponse
     */
    frozen: boolean;
    /**
     * 
     * @type {Date}
     * @memberof ModelInfoResponse
     */
    readonly timestampLastModified: Date;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoResponse
     */
    readonly lastModifiedByNameFull: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInfoResponse
     */
    readonly authorNameFull: string;
    /**
     * 
     * @type {number}
     * @memberof ModelInfoResponse
     */
    typeModel: number;
    /**
     * 
     * @type {Array<ModelInfoMutationResponse>}
     * @memberof ModelInfoResponse
     */
    mutations: Array<ModelInfoMutationResponse>;
    /**
     * 
     * @type {ProjectRoleEnum}
     * @memberof ModelInfoResponse
     */
    projectRole: ProjectRoleEnum;
}

/**
 * Check if a given object implements the ModelInfoResponse interface.
 */
export function instanceOfModelInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "frozen" in value;
    isInstance = isInstance && "timestampLastModified" in value;
    isInstance = isInstance && "lastModifiedByNameFull" in value;
    isInstance = isInstance && "authorNameFull" in value;
    isInstance = isInstance && "typeModel" in value;
    isInstance = isInstance && "mutations" in value;
    isInstance = isInstance && "projectRole" in value;

    return isInstance;
}

export function ModelInfoResponseFromJSON(json: any): ModelInfoResponse {
    return ModelInfoResponseFromJSONTyped(json, false);
}

export function ModelInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': json['description'],
        'frozen': json['frozen'],
        'timestampLastModified': (new Date(json['timestamp_last_modified'])),
        'lastModifiedByNameFull': json['last_modified_by_name_full'],
        'authorNameFull': json['author_name_full'],
        'typeModel': json['type_model'],
        'mutations': ((json['mutations'] as Array<any>).map(ModelInfoMutationResponseFromJSON)),
        'projectRole': ProjectRoleEnumFromJSON(json['project_role']),
    };
}

export function ModelInfoResponseToJSON(value?: ModelInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'frozen': value.frozen,
        'type_model': value.typeModel,
        'mutations': ((value.mutations as Array<any>).map(ModelInfoMutationResponseToJSON)),
        'project_role': ProjectRoleEnumToJSON(value.projectRole),
    };
}

