import {
  Documentation,
  GrexUserList,
  GrexUserProjectRoleListResponse,
  GrexUserSubregionListResponse,
  GrexUserTemplateReportListResponse,
  LinkBI,
  Log,
  ModelInfoMutationResponse,
  ModelListResponse,
  RoleEnum,
  TypeEnum,
  VersionModelInfoAttachmentResponse,
} from "@shared/client/lib";
import { DropDownListItem } from "@shared/components/DropDown";

export enum PopUpState {
  Hidden = "Hidden",
  // Model Alerts
  OpenModelLoading = "OpenModelLoading",
  ModelAlreadyOpenAlert = "ModelAlreadyOpenAlert",

  // Report Alerts
  OpenReportLoading = "OpenReportLoading",

  // Delete Alerts
  DeleteTypeProjectAlert = "DeleteTypeProjectAlert",
  DeleteSubregionAlert = "DeleteSubregionAlert",
  DeleteAttachmentAlert = "DeleteAttachmentAlert",
  DeleteProjectAlert = "DeleteProjectAlert",
  DeleteVersionModelAlert = "DeleteVersionModelAlert",
  DeleteModelAlert = "DeleteModelAlert",
  CancelModelSessionEditAlert = "CancelSessionEditAlert",
  DeleteCategoryAttachmentAlert = "DeleteCategoryAttachmentAlert",
  PasswordResetRequiredAlert = "PasswordResetRequiredAlert",
}

export enum ScreenState {
  // Project
  Projects = "Projects",
  Projects_NewProject = "Projects_NewProject",
  Projects_ProjectInfo = "Projects_ProjectInfo",
  Projects_NewVersionModel = "Projects_NewVersionModel",
  Projects_VersionModelInfo = "Projects_VersionModelInfo",
  Projects_VersionModelInfo_NewAttachment = "Projects_VersionModelInfo_NewAttachment",
  Projects_NewModel = "Projects_NewModel",
  Projects_ModelInfo = "Projects_ModelInfo",

  // Reports
  Reports = "Reports",
  BiLinks = "BiLinks",
  NewBiLink = "NewBiLink",

  // Configuration
  Configuration_Subregions = "Configuration_Subregions",
  Configuration_PhaseDefinitions = "Configuration_PhaseDefinitions",
  Configuration_FileCategories = "Configuration_FileCategories",
  Configuration_TypeProject = "Configuration_TypeProject",

  // Admin
  UserList = "UserList",
  Logs = "Logs",
  CreateUser = "CreateUser",
  EditUser = "EditUser",
  ResetPasswordUser = "ResetPasswordUser",

  // Documentation
  Documentation = "Documentation",

  // User
  User = "User",
  SetupMfa = "SetupMfa",

  // MapViewer
  MapViewer_VersionRegion = "MapViewer_VersionRegion",
  MapViewer_Subregion = "MapViewer_Subregion",
  MapViewer_Feature = "MapViewer_Feature",
  MapViewer_Overview = "MapViewer_Overview",
  MapViewer_Client = "MapViewer_Client",
}

export enum LoginState {
  Loading = "Loading",
  LoginEmailPassword = "LoginEmailPassword",
  LoginSaml = "LoginSaml",
  LoginMFA = "LoginMFA",
  ResetPasswordEmail = "ResetPasswordEmail",
  ResetPasswordCode = "ResetPasswordCode",
  ResetPasswordMFA = "ResetPasswordMFA",
  ResetPasswordNewPassword = "ResetPasswordNewPassword",
}

export enum ProjectSortOption {
  LastEdited = "LastEdited",
  Alphabetical = "Alphabetical",
  ProjectNumber = "ProjectNumber",
}

export enum MoveOverviewState {
  Hidden = "Hidden",
  MoveVersionModel = "MoveVersionModel",
  MoveModel = "MoveModel",
}

export interface VersionModelOverviewType {
  id: number;
  name: String;
}

export interface PhaseOverviewType {
  id: number;
  description: string;
  newDescription: string;
  saveButtonVisible: boolean;
}

export interface SubregionOverviewType {
  id: number;
  description: string;
  newDescription: string;
  saveButtonVisible: boolean;
  outlineUri: string;
}

export interface CategoryAttachmentOverviewType {
  id: number;
  name: string;
  newName: string;
  saveButtonVisible: Boolean;
}

export interface ProjectListItem {
  readonly id: number;
  name: string;
  number: string | null;
  subregion: number;
  typeProject: number;
  readonly timestampLastModified: Date;
  visible: Boolean;
  role: RoleEnum;
}

export interface TypeProjectOverviewType {
  id: number;
  description: string;
  newDescription: string;
  saveButtonVisible: boolean;
}

export interface AppStateType {
  loggedIn: boolean;
  currentUser: {
    fullName: string;
    userRole: RoleEnum;
    accessToken: string | null;
    userEmail: string;
    mfaVerified: boolean;
    mfaForced: boolean;
    isSuperAdmin: boolean;
    mfaInput: string;
    mfaInputTwo: string;
    passwordResetRequired: boolean;
  };
  LoginScreen: {
    login_error: string;
  };

  loginState: LoginState;

  popUpState: PopUpState;

  screenState: ScreenState;
  // Login with 2fa
  mfaRequired: boolean;
  login2fa: {
    useremail: string;
    infoMessage: string;
    errorMessage: string;
  };

  // Setup MFA
  setupMfa: {
    setup_key: string;
    otpauth_url: string;
    errorMessage: string;
    mfaCode: string;
  };
  // Reset password
  ResetPassword: {
    email: string;
    resetPasswordCode: string;
    mfaenabled: boolean;
    mfatoken: string;
    resetpasswordMessage: string;
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    newPasswordError: string;
    newPasswordinfo: string;
    mfaInput: string;
  };
  loading: boolean;

  projectScreen: {
    loadingProjects: boolean;
    projectsList: ProjectListItem[];
    selectedProject: number | null;
    loadingVersionModels: boolean;
    versionsList: VersionModelOverviewType[];
    selectedVersionModel: number | null;
    loadingModels: boolean;
    modelsList: ModelListResponse[];
    popUpPosition: {
      x: number;
      y: number;
    };
    projectPopUpProjectId: number | null;
    projectPopUpVersionModelId: number | null;
    projectPopUpModelId: number | null;
    typeProjectList: DropDownListItem[];
    filterTypeProject: number;
    subregions: DropDownListItem[];
    filterSubregion: number;
    projectSorting: ProjectSortOption;
    moveOverviewState: MoveOverviewState;
    versionModelToMove: number | null;
    modelToMove: number | null;
    modelToOpen: number | null;
    selectedMoveTargetProject: number | null;
    selectedMoveTargetVersionModel: number | null;
    moveVersionModelList: VersionModelOverviewType[];
    projectToDelete: number | null;
    versionModelToDelete: number | null;
    modelToDelete: number | null;
    modelToCancelSessionEdit: number | null;
    userEmailModelToCancelEditSession: string | null;
    errorMessage: string;
  };

  newProjectScreen: {
    loadingForm: boolean;
    loadingRoles: boolean;
    formError: string;
    projectName: string;
    description: string;
    typeProject: number;
    projectNumber: string;
    subregion: number;
    organisation: string;
    projectManager: string;
    userRoles: {
      id: number;
      nameFull: string;
      role: RoleEnum;
    }[];
    typeProjectList: DropDownListItem[];
    subregions: DropDownListItem[];
  };

  projectInfoScreen: {
    loadingForm: boolean;
    loadingRoles: boolean;
    formError: string;
    projectId: number | null;
    projectName: string;
    description: string;
    typeProject: number;
    projectNumber: string;
    subregion: number;
    organisation: string;
    projectManager: string;
    author: string;
    lastEdited: Date | null;
    lastModifiedByNameFull: string;
    userRoles: {
      id: number;
      nameFull: string;
      role: RoleEnum;
    }[];
    typeProjectList: DropDownListItem[];
    subregions: DropDownListItem[];
    projectRole: RoleEnum | null;
  };

  newVersionModelScreen: {
    loadingForm: boolean;
    formError: string;
    versionName: string;
    description: string;
    phases: DropDownListItem[];
    phase: number;
  };

  versionInfoScreen: {
    loadingForm: boolean;
    formError: string;
    versionId: number | null;
    versionName: string;
    description: string;
    phases: DropDownListItem[];
    phase: number;
    author: string;
    lastEdited: Date | null;
    lastModifiedByNameFull: string;
    attachments: VersionModelInfoAttachmentResponse[] | null;
    projectRole: RoleEnum | null;
    viewerUri: string | null;
    attachmentToDelete: number | null;
  };

  versionInfoNewAttachmentScreen: {
    formError: string;
    loadingForm: boolean;
    attachmentType: TypeEnum;
    categoryAttachments: DropDownListItem[];
    categoryAttachment: number;
    linkUrl: string | null;
    displayName: string | null;
    file: File | null;
  };

  newModelScreen: {
    loadingForm: boolean;
    formError: string;
    modelName: string;
    description: string;
    modelTypes: DropDownListItem[];
    modelType: number;
  };

  modelInfoScreen: {
    loadingForm: boolean;
    formError: string;

    modelId: number | null;
    modelName: string;
    description: string;
    modelTypes: DropDownListItem[];
    modelType: number;
    frozen: boolean;
    author: string;
    lastEdited: Date | null;
    lastModifiedByNameFull: string;
    mutations: ModelInfoMutationResponse[] | null;
    projectRole: RoleEnum | null;
    modelHistoryList: {
      id: number;
      active: boolean;
      fileSize: number;
      filenameOrig: string;
      timestampSave: Date;
      userSave: string;
    }[];
    newHistoryFile: File | null;
    fileUploadSuccessMessage: string;
    fileUploadErrorMessage: string;
  };

  reportScreen: {
    loadingSpinner: boolean;
    loadingProjects: boolean;
    projectsList: ProjectListItem[];
    projectsHaveModelsList: number[];
    selectedProject: number | null;
    loadingVersionModels: boolean;
    versionsList: VersionModelOverviewType[];
    selectedVersionModel: number | null;
    loadingModels: boolean;
    modelsList: ModelListResponse[];
    formError: string;
    correctSelectedModelAmount: boolean;
    allProjectsSelected: boolean;
    selectedModels: {
      model: number;
      versionModel: number;
      project: number;
    }[];
    typeProject: DropDownListItem[];
    filterTypeProject: number;
    subregions: DropDownListItem[];
    filterSubregion: number;
    projectSorting: ProjectSortOption;
    templateReports: {
      id: number;
      description: string;
      nModelsMin: number;
      nModelsMax: number;
      name: string;
    }[];
    selectedTemplateReport: number;
    loadAndSelectModel: boolean;
    errorMessage: string;
    progressPercentage: number;
  };

  phaseScreen: {
    formError: string;
    phasesList: PhaseOverviewType[];
    newPhase: string;
  };

  subregionScreen: {
    formError: string;
    subregionsList: SubregionOverviewType[];
    newSubregion: string;
    subregionToDelete: number | null;
  };

  typeProjectScreen: {
    formError: string;
    typeProjectList: TypeProjectOverviewType[];
    newTypeProject: string;
    typeProjectToDelete: number | null;
  };

  categoryAttachmentScreen: {
    formError: string;
    categoryAttachmentsList: CategoryAttachmentOverviewType[];
    newCategory: string;
    categoryAttachmentToDelete: number | null;
  };

  logScreen: {
    logs: Log[];
    errorMessage: string;
  };

  userScreen: {
    userId: number;
    firstName: string;
    lastName: string;
    email: string;
    mfaVerified: boolean;
    mfaForced: boolean;
    infoMessage: string;
    errorMessage: string;
  };
  PasswordPolicy: {
    newPasswordRequirements: string[];
    notAppliedRequirements: string[];
  };
  userNewPasswordScreen: {
    oldPassword: string;
    newPassword: string;
    newPasswordRepeat: string;
    newPasswordError: string;
    newPasswordinfo: string;
  };
  UserListScreen: {
    userList: GrexUserList[];
    userlist_message: string;
  };

  NewUser: {
    firstName: string;
    lastName: string;
    email: string;
    role: RoleEnum;
    password: string;
    passwordRepeat: string;
    NewUserError: string;

    subregions: GrexUserSubregionListResponse[];
    subregions_message: string;
    templateReports: GrexUserTemplateReportListResponse[];
    templateReports_message: string;
    projectsRoles: GrexUserProjectRoleListResponse[];
    projectsRoles_message: string;
    allProjectRolesDropdown: RoleEnum | null;
  };

  EditUser: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    role: RoleEnum;
    password: string;
    passwordRepeat: string;
    mfaVerified: boolean;
    mfaForced: boolean;
    blocked: boolean | undefined;
    lastLogin: Date | null;
    EditUserError: string;

    subregions: GrexUserSubregionListResponse[];
    subregions_message: string;
    templateReports: GrexUserTemplateReportListResponse[];
    templateReports_message: string;
    projectsRoles: GrexUserProjectRoleListResponse[];
    projectsRoles_message: string;
    allProjectRolesDropdown: RoleEnum | null;

    newPassword: string;
    newPasswordRepeat: string;
    newPasswordError: string;
    newPasswordinfo: string;
  };

  DocumentationScreen: {
    errorMessage: string;
    // documentations: Documentation[];
    documentationsManual: Documentation[];
    documentationsInstructionalVideo: Documentation[];
    documentationsReleaseNote: Documentation[];
  };

  SubregionMapScreen: {
    outlineUri: string | null;
  };

  VersionRegionMapScreen: {
    outlineUri: string | null;
  };

  FeatureMapScreen: {
    editorUri: string | null;
  };

  ClientMapScreen: {
    outlineUri: string | null;
    errorMessage: string;
  };

  OverviewMapScreen: {
    viewerUri: string | null;
    errorMessage: string;
  };

  NewBiLinkScreen: {
    formError: string;
    description: string;
    selectedModels: {
      model: number;
      versionModel: number;
      project: number;
    }[];
  };

  BiLinksScreen: {
    biLinks: LinkBI[];
    errorMessage: string;
  };
}
