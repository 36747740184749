/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GrexUserResetMfa
 */
export interface GrexUserResetMfa {
    /**
     * 
     * @type {boolean}
     * @memberof GrexUserResetMfa
     */
    mfaVerified?: boolean;
}

/**
 * Check if a given object implements the GrexUserResetMfa interface.
 */
export function instanceOfGrexUserResetMfa(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GrexUserResetMfaFromJSON(json: any): GrexUserResetMfa {
    return GrexUserResetMfaFromJSONTyped(json, false);
}

export function GrexUserResetMfaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GrexUserResetMfa {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfaVerified': !exists(json, 'mfa_verified') ? undefined : json['mfa_verified'],
    };
}

export function GrexUserResetMfaToJSON(value?: GrexUserResetMfa | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfa_verified': value.mfaVerified,
    };
}

