/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Documentation,
} from '../models';
import {
    DocumentationFromJSON,
    DocumentationToJSON,
} from '../models';

export interface ApiGrexmanagerDocumentationRetrieveRequest {
    id: number;
}

/**
 * 
 */
export class DocumentationApi extends runtime.BaseAPI {

    /**
     * load Documentation
     */
    async apiGrexmanagerDocumentationListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Documentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/documentation/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentationFromJSON));
    }

    /**
     * load Documentation
     */
    async apiGrexmanagerDocumentationList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Documentation>> {
        const response = await this.apiGrexmanagerDocumentationListRaw(initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for downloading a Documentation
     */
    async apiGrexmanagerDocumentationRetrieveRaw(requestParameters: ApiGrexmanagerDocumentationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiGrexmanagerDocumentationRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/grexmanager/documentation/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Endpoint for downloading a Documentation
     */
    async apiGrexmanagerDocumentationRetrieve(requestParameters: ApiGrexmanagerDocumentationRetrieveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.apiGrexmanagerDocumentationRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
