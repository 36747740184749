/* tslint:disable */
/* eslint-disable */
/**
 * GrexBackend API
 * GrexBackend API
 *
 * The version of the OpenAPI document: 24.12.02.12.59
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ModelMoveRequest
 */
export interface ModelMoveRequest {
    /**
     * 
     * @type {number}
     * @memberof ModelMoveRequest
     */
    toVersion: number;
}

/**
 * Check if a given object implements the ModelMoveRequest interface.
 */
export function instanceOfModelMoveRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "toVersion" in value;

    return isInstance;
}

export function ModelMoveRequestFromJSON(json: any): ModelMoveRequest {
    return ModelMoveRequestFromJSONTyped(json, false);
}

export function ModelMoveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ModelMoveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'toVersion': json['to_version'],
    };
}

export function ModelMoveRequestToJSON(value?: ModelMoveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'to_version': value.toVersion,
    };
}

